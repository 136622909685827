<template>
    <!-- 密码设置 -->
    <div class="setpwd_box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item>
                <span style="color:red;font-size:14px;">* 登录账号与登录密码只能输入小写字母、数字、下划线</span>
            </el-form-item>
            <el-form-item label="登录账号" prop="LoginName" style="position: relative;">
                <el-input v-model="ruleForm.LoginName" placeholder="请输入登录账号" type="text" maxlength="20" @blur="LoginNameBlur" onkeyup="this.value=this.value.replace(/[^a-z0-9_]/g,'')"></el-input>
                <div class="acctip">
                    <span class="msg ok" v-if="accIsUse==false"><i class="iconfont">&#xe754;</i>可使用</span>
                    <span class="msg no" v-if="accIsUse==true"><i class="iconfont">&#xe620;</i>已被注册</span>
                </div>
            </el-form-item>
            <el-form-item label="登录密码" prop="Password">
                <el-input v-model="ruleForm.Password" placeholder="请输入登录密码" :type="pwdtype" maxlength="20" onkeyup="this.value=this.value.replace(/[^a-z0-9_]/g,'')"></el-input>
                <i v-if="pwdstatus" class="iconfont" @click.prevent="lookpwdbtn(false)">&#xe61b;</i>
                <i v-else class="iconfont" @click.prevent="lookpwdbtn(true)">&#xe61c;</i>
            </el-form-item>
            <el-form-item label="确认密码" prop="surepassword">
                <el-input v-model="ruleForm.surepassword" placeholder="请输入确认密码" :type="pwdtype" maxlength="20" onkeyup="this.value=this.value.replace(/[^a-z0-9_]/g,'')"></el-input>
                <i v-if="pwdstatus" class="iconfont" @click.prevent="lookpwdbtn(false)">&#xe61b;</i>
                <i v-else class="iconfont" @click.prevent="lookpwdbtn(true)">&#xe61c;</i>
            </el-form-item>
            <el-form-item style="text-align: center;">
                <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                <el-button type="info" @click.prevent="clearForm('ruleForm')">清空</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {Message} from 'element-ui';
import API from "@/api/config";
export default{
    data(){
        return {
            pwdtype:'password',
            pwdstatus:true,
            accIsUse:null,
            ruleForm: {
                LoginName: '',
                Password:'',
                surepassword:''
            },
            rules: {
                LoginName: [
                    { required: true, message: '请输入登录账号', trigger: 'blur' }
                ],
                Password: [
                    { required: true, message: '请输入登录密码', trigger: 'blur' }
                ],
                surepassword: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' }
                ],
            }
        }
    },
    mounted(){
        //检查是否初始化了账号
        API.Personal.GetUserStudent().then(res => {
            if(res.Code===0){
                if(res.Data!=null){
                    //初始化过账号跳转到个人中心首页
                    if(res.Data.HasLoginName == true){
                        this.$router.push('/userinfo');
                    }
                }
            }
        });
    },
    methods:{
        //检查账号
        LoginNameBlur(){
            if(this.ruleForm.LoginName.trim()){
                API.Personal.CheckLoginNameExists({LoginName:this.ruleForm.LoginName}).then(res => {
                    if(res.Code===0){
                        this.accIsUse=res.Data;
                    }
                });
            }
        },
        //保存
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.ruleForm.Password.length<6){
                        Message({ type: 'error', message: '登录密码至少6位！' }); return false;
                    }
                    if(this.ruleForm.Password!=this.ruleForm.surepassword){
                        Message({ type: 'error', message: '两次密码输入不一致！' }); return false;
                    }
                    this.$confirm('确定提交账号信息？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        API.Personal.SetInitialLoginName(this.ruleForm).then(res => {
                            if(res.Code===0){
                                Message({ type: 'success', message: '设置成功！' });
                                location.reload();//刷新当前页面
                            }
                        });
                    }).catch(() => {
                    });
                }
            });
        },
        //清空
        clearForm(formName){
            this.$refs[formName].resetFields();
        },
        //密码显示切换
        lookpwdbtn(b){
            this.pwdstatus=b;
            if(b){
                this.pwdtype="password";
            }
            else{
                this.pwdtype="text";
            }
        },
    }
}
</script>
<style lang="less" scoped>
.setpwd_box{
    margin: 10px;
    border: 1px solid #e4e4e4;
    padding: 20px 0 0;
    .acctip{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 1;
        .msg{
            font-size: 12px;
            i{
                position:initial;
                display: inline-block;
                font-size: 14px;
                width: auto;
                margin-right: 3px;
            }
        }
        .ok{
            i{
                color: #077018;
            }
            color: #077018;
        }
        .no{
            i{
                color: #861d0a;
            }
            color: #861d0a;
        }
    }
}
</style>
<style lang="less">
.setpwd_box .el-form .el-form-item{
    max-width: 80%;
    min-width: 400px;
}
.setpwd_box .el-form .el-form-item i{
    position: absolute;
    right: 0px;
    top: 0px;
    width: 30px;
    text-align: center;
    font-size: 16px;
    color: #666;
    user-select: none;
}
.setpwd_box .el-form .el-form-item .getpwd{
    text-decoration: underline;
    color: #666;
    font-size: 14px;
    display:inline-block;
    user-select: none;
}
.setpwd_box .el-form .el-form-item .getpwd:hover{
    color: rgb(var(--themecolor));
    cursor: pointer;
}
.setpwd_box .el-form .el-form-item i:hover{
    cursor: pointer;
    color: rgb(var(--themecolor));
}
.setpwd_box .el-form .el-form-item .el-button--primary{
    width: 120px;
    background-color: rgba(var(--themecolor),0.85);
    border-color: rgba(var(--themecolor),0.85);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}
.setpwd_box .el-form .el-form-item .el-button--primary:hover{
    background-color: rgba(var(--themecolor),1);
    border-color: rgba(var(--themecolor),1);
}
.setpwd_box .el-form .el-form-item .el-input__inner:focus{
    border-color: #ddd;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
</style>
